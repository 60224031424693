/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository, { defaults as baseDefaults } from './BaseRepository'
import CatalogPricingRulesServices, { CouponServices } from 'services/catalogPricingRule'

const defaults = {
  Attributes: {
    loading: false,
    data: null,
  },
}

class CatalogPricingRuleRepository extends BaseRepository {
  service: CatalogPricingRulesServices
  Attributes = defaults.Attributes
  Export = baseDefaults.CRUD

  constructor(service: CatalogPricingRulesServices) {
    super(service)
    this.service = service
  }
  fetchCouponTable = async (params, callback) => {
    this.Table.loading = true
    const { data, errors } = await this.service.fetchCouponTable(params)
    if (data && !errors.length) {
      this.Table.originalData = data
      this.Table.data = data.content
      this.Table.totalRecordCount = data.totalElements
      callback && callback(data)
    }
    this.Table.loading = false
  }
  getAttributesPricingRules = async () => {
    this.Attributes.loading = true
    const { data, errors } = await this.service.getAttributesPricingRules()
    if (data && !errors.length) {
      this.Attributes = {
        loading: false,
        data,
      }
    } else {
      this.Attributes.loading = false
    }
  }
  getAttributeValues = async payload => {
    const { data, errors } = await this.service.getAttributeValues(payload)
    return { data, errors }
  }
  copyRules = async (payload, callback) => {
    this.CRUD.submitting = true
    const { data, errors } = await this.service.copyRules(payload)
    this.CRUD.submitting = false
    callback({ data, errors })
  }
  clearAttributes = () => {
    this.Attributes = defaults.Attributes
  }
  export = async (payload, callback) => {
    this.Export.errors = []
    this.Export.loading = true
    const { data, errors } = await this.service.export(payload, { responseType: 'blob' })
    if (data && !errors.length) {
      this.Export.loadingFailed = false
      if (callback) callback(data)
    } else {
      this.Export.loadingFailed = true
      this.Export.errors = errors
    }
    this.Export.loading = false
  }
}

class Coupon extends BaseRepository {
  service: CouponServices
  constructor(service: CouponServices) {
    super(service)
    this.service = service
  }
  AllCodesTable = {
    originalData: {},
    data: [],
    loading: false,
    totalRecordCount: 0,
  }
  reactSelectSearch = async (params, id = null) => {
    const { data, errors } = await this.service.reactSelectSearch(params, id)
    return {
      data: {
        ...data,
        content: data.content.map(item => ({
          ...item,
          customerLabel: item.partnerId && item.partnerId !== '0' ? `${item.name} (${item.partnerId})` : item.name,
        })),
      },
      errors,
    }
  }
  fetchAllCodesTable = async (params, callback) => {
    this.AllCodesTable.loading = true
    const { data, errors } = await this.service.fetchAllCodesTable(params)
    if (data && !errors.length) {
      this.AllCodesTable.originalData = data
      this.AllCodesTable.data = data.content
      this.AllCodesTable.totalRecordCount = data.totalElements
      callback && callback(data)
    }
    this.AllCodesTable.loading = false
  }
  deleteBulk = async (payload, callback) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    const { data, errors } = await this.service.deleteBulk(payload)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }
}

export const CouponRepository = decorate(Coupon, {
  AllCodesTable: observable,
})

export default decorate(CatalogPricingRuleRepository, {
  Attributes: observable,
  Export: observable,
})
