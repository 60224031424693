import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import cx from 'classnames'
import React from 'react'

class ContextMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }
  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  render() {
    const {
      disabled,
      dropDownToggle = null,
      menus = [],
      plain = false,
      className,
      dropdownToggleClassName,
    } = this.props
    return (
      <ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggle} className={cx(className)}>
        {dropDownToggle && !plain ? (
          <DropdownToggle caret color="primary" disabled={disabled} className={cx(dropdownToggleClassName)}>
            {dropDownToggle}
          </DropdownToggle>
        ) : (
          <DropdownToggle tag="span" data-toggle="dropdown" className={cx('cursor-pointer', dropdownToggleClassName)}>
            {dropDownToggle}
          </DropdownToggle>
        )}
        <DropdownMenu>
          {menus
            .filter(item => !item.hidden)
            .map(({ text, onClick, disabled = false }, key) => (
              <DropdownItem key={key} disabled={disabled} onClick={onClick}>
                {text}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </ButtonDropdown>
    )
  }
}

export default ContextMenu
