/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class CatalogPricingRuleServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/pricingRules'
    super(api, url)
    this.api = api
    this.url = url
  }
  fetchCouponTable(payload) {
    return this.api.get(`${this.url}/couponRules`, payload)
  }
  getAttributesPricingRules = async () => {
    return await this.api.get(`${this.url}/coupons/criteria`)
  }
  getAttributeValues = async payload => {
    return await this.api.get('/products/attributes/values', payload)
  }
  delete(payload) {
    return this.api.post(`${this.url}/remove`, payload)
  }
  copyRules(payload) {
    return this.api.post(`${this.url}/copy`, payload)
  }
  export(payload, headers = {}) {
    return this.api.get(`${this.url}/export`, payload, headers)
    // const params = Object.keys(payload).length ? `?${Object.toParams(payload)}` : ''
    // return this.api.post(`${this.url}/export${params}`, null, headers)
  }
}

export class CouponServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/pricingRules'
    super(api, url)
    this.api = api
    this.url = url
  }
  reactSelectSearch(payload, pricingRuleId) {
    return this.api.get(`${this.url}/${pricingRuleId}/customers`, payload)
  }
  fetchTable(pricingRuleId, payload) {
    return this.api.get(`${this.url}/${pricingRuleId}/coupons`, payload)
  }
  fetchAllCodesTable(payload) {
    return this.api.get(`${this.url}/coupons`, payload)
  }
  getById({ pricingRuleId, id }) {
    return this.api.get(`${this.url}/${pricingRuleId}/coupons/${id}`)
  }
  delete({ pricingRuleId, id }) {
    return this.api.del(`${this.url}/${pricingRuleId}/coupons/${id}`)
  }
  patch({ pricingRuleId, id, ...payload }) {
    return this.api.patch(`${this.url}/${pricingRuleId}/coupons/${id}`, payload)
  }
  create({ pricingRuleId, ...payload }) {
    return this.api.post(`${this.url}/${pricingRuleId}/coupons`, payload)
  }
  deleteBulk(payload) {
    return this.api.post(`${this.url}/coupons/remove`, payload)
  }
}
