import { COTERM_PATH } from 'definitions'
import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import _ from 'lodash'
import queryString from 'query-string'

import { formatDecimalValByCustomer, formatOrderPayload, getFormattedDateTimeString } from 'helpers/Formatters'
import { ORDER_SOURCE, ORDER_STATUS_NEW, ORDER_STATUS_PENDING_APPROVAL } from 'definitions'
import { stripEmptyValues } from 'helpers/Common'
import { Visible } from 'helpers/Auth'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import FormContainer from 'components/Common/Form/Container'
import MobxForm from 'components/Common/Form/index'
import ToasterNotify from 'helpers/ToasterNotify'

import { getFields } from 'components/Orders/OrderForm/fieldset'
import { OrderFormFunctions } from 'components/Orders/OrderForm/'
import Actions from './Actions'
import ApprovalRequestModal from 'components/Orders/OrderForm/ApprovalRequestModal'
import BillingShippingPanel from 'components/Orders/OrderForm/BillingShippingPanel'
import CommentPanel from 'components/Orders/OrderForm/CommentPanel'
import CommentSidePanel from 'components/Orders/OrderForm/CommentSidePanel'
import ContextMenu from 'components/Common/ContextMenu'
import CoTermCustomerRemarksPanel from 'components/Orders/OrderForm/CoTermCustomerRemarksPanel'
import CustomerPanel from 'components/Orders/OrderForm/CustomerPanel'
import MailingList from 'components/Orders/OrderForm/MailingList'
import OrderSummary from 'components/Orders/OrderForm/OrderSummary'
import PaymentPanel from 'components/Orders/OrderForm/PaymentPanel'
import ProductsPanel from './ProductsPanel'
import ShippingMethodPanel from 'components/Orders/OrderForm/ShippingMethodPanel'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class OrderForm extends Component {
  payload = {}
  formAction = ''
  constructor(props) {
    super(props)

    const { edit } = queryString.parse(props.location.search)
    const order = props.orderRepository.CRUD.data
    const data = order ? toJS(order) : {}
    const { id } = data
    const isSimulated = data.orderSource === ORDER_SOURCE.COTERM_SIMULATOR_PARTNER_PAVILION
    const readOnly =
      !(edit && edit === 'true' && [ORDER_STATUS_NEW, ORDER_STATUS_PENDING_APPROVAL].includes(data.status)) ||
      isSimulated

    const fields = {
      ...getFields({
        data: {
          ...data,
          quotationDaysValidFor: 14,
        },
        requireQuotationDaysValidFor: fieldData => this.requireQuotationDaysValidFor(fieldData),
        virtualProductOnlyInd: true,
      }),
      sendQuotationEmailInd: {
        type: 'checkbox',
        value: data.sendQuotationEmailInd,
        label: 'Send quotation email',
      },
    }

    this.form = new MobxForm(
      { fields },
      {
        name: 'OrderForm',
        hooks: {
          onSuccess: form => {
            this.handleSubmit(form)
          },
          onError: this.handleFormError,
        },
      }
    )

    this.state = {
      formErrors: null,
      activeCommentPanelType: '',
      isSaveQuotationModalOpen: false,
      isApprovalRequestModalOpen: false,
      readOnly,
      readOnlyCoupon: readOnly,
      skipPromptOnExit: false,
    }

    this.computeOrderSummary = _.debounce(this.computeOrderSummary, 350, { leading: false, trailing: true }).bind(this)

    if (id) {
      this.updateOrderSummary(order.orderSummary)
    }
  }
  componentDidUpdate(prevProps) {
    const errors = toJS(this.props.orderRepository.CRUD.errors)
    const prevErrors = toJS(prevProps.orderRepository.CRUD.errors)
    const statusErrors = toJS(this.props.orderRepository.OrderStatus.errors)
    const prevStatusErrors = toJS(this.props.orderRepository.OrderStatus.errors)
    const invalidateField = err =>
      err.fieldName && this.form.has(err.fieldName) && this.form.$(err.fieldName).invalidate(err.message)

    if (errors !== prevErrors) {
      errors.filter(invalidateField)
    }
    if (statusErrors !== prevStatusErrors) {
      statusErrors.filter(invalidateField)
    }
  }
  componentWillUnmount() {
    this.props.orderRepository.clearErrorMessages()
  }
  gotoOrders = () => {
    this.props.history.push('/co-term')
  }
  handleCancel = () => {
    this.props.history.push(COTERM_PATH)
  }
  handleCancelOrder = () => {
    const {
      orderRepository: {
        cancelOrder,
        CRUD: { data },
      },
    } = this.props
    ToasterNotify.confirm({
      message: 'Are you sure you want to cancel this order?',
      title: 'Warning',
      onConfirm: () => {
        cancelOrder(data.id).then(res => {
          this.setState({ skipPromptOnExit: true })
          this.handleCancel()
        })
      },
    })
  }
  handleFormError = form => {
    this.setState({
      formErrors: form.hasError
        ? [{ message: 'There are validation errors in this order. Please review your order' }]
        : null,
    })
  }
  handleSubmit = form => {
    const values = { ...toJS(form.values()) }
    const {
      orderRepository: {
        saveAsDraft,
        saveOrder,
        create,
        orderRevision,
        CRUD: { data },
        OrderRates,
      },
    } = this.props

    const payload = formatOrderPayload({
      ...data,
      ...values,
      payment: {
        ...values.payment,
        additionalCharge: OrderRates.data.additionalCharge || 0,
      },
      ...(values.groups.length ? { groups: values.groups } : { groups: [] }),
    })
    payload.shippingMethod = { ...data.shippingMethod, ...payload.shippingMethod }

    const type = this.formAction
    let doPatch
    switch (type) {
      case 'draft':
      case 'save-coupon':
        doPatch = saveAsDraft
        break
      default:
        doPatch = saveOrder
        break
    }

    const submit = () => {
      if (data.id) {
        doPatch(
          payload,
          () => {
            this.setState({
              readOnly: true,
              readOnlyCoupon: true,
            })
            this.handleCancel()
          },
          data.id
        )
      } else
        create(payload, () => {
          this.handleCancel({})
        })
    }

    if (data.orderNumber && type !== 'save-coupon') {
      ToasterNotify.confirm({
        message: 'Are you sure? This order will be canceled and a new order will be created.',
        title: 'Warning',
        onConfirm: () => {
          orderRevision(data.id, res => {
            this.handleCancel({})
          })
        },
      })
    } else {
      submit()
    }
  }
  handleOpenDrawer = type => {
    this.setState({ activeCommentPanelType: type }, () => {
      this.drawer && this.drawer.openDrawer('comments')
    })
  }
  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }
  handleDiscardChanges = () => {
    const {
      match: { params },
      orderRepository: { getById },
    } = this.props

    ToasterNotify.confirm({
      message: `Are you sure you want to Discard the changes?`,
      title: 'Warning',
      onConfirm: () => {
        getById(params.orderId, () => {
          this.form.reset()
          this.computeOrderSummary(false)
          this.setState({
            readOnly: true,
            readOnlyCoupon: true,
          })
        })
      },
    })
  }
  handleSendEmail = async () => {
    const { errors } = await this.props.orderRepository.sendEmail(this.props.data.id)
    if (!errors.length) {
      ToasterNotify.alert({ message: 'Email sent' })
    }
  }
  handleConvertQuotation = () => {
    const {
      data,
      orderRepository: { convertCoTermQuotation },
    } = this.props

    convertCoTermQuotation(data.id, () => {
      this.setState({
        readOnly: true,
        readOnlyCoupon: true,
      })
      this.gotoOrders()
    })
  }
  handleSendEmail = async () => {
    const { errors } = await this.props.orderRepository.sendEmail(this.props.data.id)
    if (!errors.length) {
      ToasterNotify.alert({ message: 'Email sent' })
    }
  }
  computeOrderSummary = async (updateShippingTotal, updatePayment = true) => {
    const orderItems = this.props.orderRepository.CRUD.data.orderItems
    const payload = {
      ...this.form.values(),
      orderItems,
      updateShippingTotal,
      updateSurcharge: updatePayment,
    }

    //remove empty values from the root level object
    stripEmptyValues(payload)
    stripEmptyValues(payload.billingAddress)
    stripEmptyValues(payload.shippingAddress)

    await this.props.orderRepository.orderSummary(formatOrderPayload(payload), result => {
      if (updateShippingTotal) {
        this.form.$('shippingMethod.totalShippingFee').set(result.shippingTotal)
      }
      if (updatePayment) {
        const customer = this.form.$('customer').value
        this.form
          .$('payment.surcharge')
          .set(result.paymentCharge ? formatDecimalValByCustomer(result.paymentCharge, customer) : '')
      }
    })
  }
  updateOrderSummary = orderSummaryData => {
    this.props.orderRepository.OrderRates.data = orderSummaryData
  }
  requireQuotationDaysValidFor = ({ field }) => {
    if (this.state && this.state.isSaveQuotationModalOpen) {
      return [field.value > 0, 'This field is required']
    }
    return [true]
  }
  toggleApprovalRequestModal = () => {
    this.setState({ isApprovalRequestModalOpen: !this.state.isApprovalRequestModalOpen })
  }
  setFieldValidations = (unset = false, formAction) => {
    const {
      orderRepository: { CRUD },
    } = this.props

    const isShippingApplicable = CRUD.data && (CRUD.data.virtualProductOnlyInd || false)
    const shippingFields = [
      'shippingAddress.firstName',
      'shippingAddress.lastName',
      'shippingAddress.email',
      'shippingAddress.streetAddress1',
      'shippingAddress.city',
      'shippingAddress.country',
      'shippingAddress.zipCode',
      'shippingMethod.method',
    ]

    let requiredFields = [
      'billingAddress.firstName',
      'billingAddress.lastName',
      'billingAddress.email',
      'billingAddress.streetAddress1',
      'billingAddress.city',
      'billingAddress.country',
      'billingAddress.zipCode',
      ...shippingFields,
      'payment.paymentMethod',
    ]

    //reset rules
    requiredFields.forEach(field => {
      this.form.$(field).set('rules', null)
    })

    const rules = !unset ? 'required' : ''
    requiredFields
      .filter(
        rf =>
          !(formAction === 'quotation' && rf === 'payment.paymentMethod') &&
          !(!!isShippingApplicable && shippingFields.includes(rf))
      )
      .forEach(field => {
        this.form.$(field).set('rules', rules)
      })

    this.form.$('shippingAddress.telNo').set('rules', '')
    if (unset) {
      this.form.$('billingAddress.streetAddress1').set('rules', '')
      this.form.$('billingAddress.streetAddress2').set('rules', '')
    } else {
      this.form.$('billingAddress.streetAddress1').set('rules', `${rules}|max:35`)
      this.form.$('billingAddress.streetAddress2').set('rules', 'max:35')
    }

    if (this.form.$('payment.paymentMethod').value === 'Bank Transfer') {
      this.form.$('payment.bankAccount').set('rules', rules)
    }
  }
  validateForm = () => {
    this.form.validate({ showErrors: true }).then(({ isValid }) => {
      if (isValid) {
        if (this.formAction === 'convert') {
          this.handleConvertQuotation()
        } else {
          this.toggleApprovalRequestModal()
        }
      }
    })
  }
  updateBankAccount = country => OrderFormFunctions.updateBankAccount(country, this)
  render() {
    const {
      breadcrumbs,
      title,
      commonRepository,
      customerRepository,
      customerAddressRepository,
      customerContactRepository,
      storeRepository,
      orderRepository: {
        CRUD: { errors, loading },
        CRUD,
        OrderStatus,
      },
      orderRepository,
      sanctionCustomerRepository,
      bankAccountRepository,
      data = {},
    } = this.props
    const { isApprovalRequestModalOpen, readOnly, readOnlyCoupon } = this.state
    let { formErrors, skipPromptOnExit } = this.state

    const repositoryErrors = errors.filter(err => !err.fieldName) || OrderStatus.errors.filter(err => !err.fieldName)
    const repositoryValidationError =
      errors.some(err => err.fieldName != null) || OrderStatus.errors.some(err => err.fieldName != null)
        ? [{ message: 'There are validation errors in this order. Please review your order' }]
        : null
    const isShippingApplicable = CRUD.data && CRUD.data.virtualProductOnlyInd

    return (
      <React.Fragment>
        <div className="content">
          <ContentContainer
            promptOnExit={orderRepository.ApprovalRequest.submitting || (!readOnly && !skipPromptOnExit)}
            promptOnExitIsConfirm={!orderRepository.ApprovalRequest.submitting}
            breadcrumbs={breadcrumbs}
            title={
              <div className="d-inline-flex align-items-start">
                <h4 className="m-0">{readOnly ? title : `Edit ${title}`}</h4>
                {data.orderSource && (
                  <span
                    className="btn btn-sm ml-4 px-4"
                    style={{ backgroundColor: 'rgba(0,0,0,0.1)', fontSize: '0.75rem' }}
                  >
                    {data.orderSource}
                  </span>
                )}
              </div>
            }
            actions={
              <Actions
                readOnly={readOnly}
                readOnlyCoupon={readOnlyCoupon}
                data={data}
                form={this.form}
                loading={loading}
                handleEditMode={() =>
                  this.setState({
                    readOnly: false,
                    readOnlyCoupon: false,
                  })
                }
                handleCouponEditMode={() => this.setState({ readOnlyCoupon: false })}
                handleSaveOrder={() => {
                  this.formAction = 'submit'
                  this.setFieldValidations()
                  this.validateForm()
                }}
                handleConvertQuotation={() => {
                  this.formAction = 'convert'
                  this.setFieldValidations()
                  this.validateForm()
                }}
                handleCancelOrder={this.handleCancelOrder}
                handleDiscardChanges={this.handleDiscardChanges}
                handleSaveDraft={() => {
                  this.formAction = 'draft'
                  this.form.submit()
                }}
                handleSaveCoupon={() => {
                  this.formAction = 'save-coupon'
                  this.form.submit()
                }}
                handleSendEmail={this.handleSendEmail}
              />
            }
          >
            <Row>
              <Col sm={12}>
                <FormContainer
                  title="Order Details"
                  onSubmit={() => this.handleSubmit(this.form)}
                  errors={formErrors || repositoryValidationError || repositoryErrors}
                  plain
                >
                  <Row>
                    <Col sm={data.id ? 12 : 4}>
                      <CustomerPanel
                        form={this.form}
                        orderRepository={orderRepository}
                        customerRepository={customerRepository}
                        commonRepository={commonRepository}
                        storeRepository={storeRepository}
                        sanctionCustomerRepository={sanctionCustomerRepository}
                        step={2}
                        computeOrderSummary={this.computeOrderSummary}
                        readOnly={readOnly}
                        recordTypeStatic={true}
                        isCoTerm
                      />
                    </Col>
                  </Row>
                  <>
                    <ProductsPanel
                      form={this.form}
                      computeOrderSummary={this.computeOrderSummary}
                      readOnly={readOnly}
                      readOnlyCoupon={readOnlyCoupon}
                      data={data}
                    />
                    <Row>
                      <Col sm="9">
                        <BillingShippingPanel
                          readOnly={readOnly}
                          form={this.form}
                          commonRepository={commonRepository}
                          customerContactRepository={customerContactRepository}
                          customerAddressRepository={customerAddressRepository}
                          orderRepository={orderRepository}
                          copyBilling={this.copyBilling}
                          toggleBillingContact={this.toggleBillingContact}
                          toggleBillingAddress={this.toggleBillingAddress}
                          computeOrderSummary={this.computeOrderSummary}
                          isShippingApplicable={isShippingApplicable}
                          updateBankAccount={this.updateBankAccount}
                        />
                      </Col>
                      <Col sm="3">
                        <ShippingMethodPanel
                          readOnly={readOnly}
                          form={this.form}
                          commonRepository={commonRepository}
                          storeRepository={orderRepository}
                          computeOrderSummary={this.computeOrderSummary}
                          isShippingApplicable={isShippingApplicable}
                        />
                        <PaymentPanel
                          orderSource={data.orderSource}
                          orderType={data.orderType}
                          readOnly={readOnly}
                          form={this.form}
                          commonRepository={commonRepository}
                          orderRepository={orderRepository}
                          bankAccountRepository={bankAccountRepository}
                          computeOrderSummary={this.computeOrderSummary}
                          summary={orderRepository.OrderRates.data}
                          updateBankAccount={this.updateBankAccount}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col sm="7">
                        <CommentPanel
                          readOnly={readOnly}
                          field={this.form.$('customerComments')}
                          label="Customer Comments"
                          buttonColor="success"
                          className="mb-2"
                          handleOpenDrawer={() => this.handleOpenDrawer('CUSTOMER')}
                        />
                        <CommentPanel
                          readOnly={readOnly}
                          field={this.form.$('internalRemarks')}
                          label="Internal Remarks"
                          buttonColor="secondary"
                          className="mb-2"
                          handleOpenDrawer={() => this.handleOpenDrawer('INTERNAL')}
                        />
                        <MailingList readOnly={readOnly} form={this.form} />
                        <CoTermCustomerRemarksPanel readOnly={readOnly} field={this.form.$('coTermCustomerRemarks')} />
                      </Col>
                      <Col sm="5" className="d-flex">
                        <OrderSummary form={this.form} storeRepository={orderRepository} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" className="d-flex align-items-end">
                        <small>{`Created by ${data.createdBy} ${getFormattedDateTimeString(data.createdDate)}`}</small>
                      </Col>
                      {data.status !== 'CANCELLED' && (
                        <Visible toOrderProcessRole>
                          <Col xs="6" className="d-flex justify-content-end">
                            <ContextMenu
                              disabled={loading || readOnly}
                              dropDownToggle="Actions"
                              menus={[
                                {
                                  text: 'Save as Draft',
                                  onClick: () => {
                                    this.formAction = 'draft'
                                    this.setFieldValidations(true)
                                    this.form.submit()
                                  },
                                  hidden: readOnly || !!data.orderNumber,
                                },
                                {
                                  text: 'Submit Quotation',
                                  onClick: () => {
                                    this.formAction = 'submit'
                                    this.setFieldValidations()
                                    this.validateForm()
                                  },
                                },
                              ]}
                            />
                          </Col>
                        </Visible>
                      )}
                    </Row>
                  </>
                </FormContainer>
              </Col>
            </Row>
          </ContentContainer>
        </div>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity="Order"
                  entityId={data.id}
                  entityLabel="Co-Term"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  orderId={data.id}
                  activeType={this.state.activeCommentPanelType}
                  handleCloseDrawer={this.handleCloseDrawer}
                  form={this.form}
                />
              ),
            },
          ]}
        />
        {isApprovalRequestModalOpen && (
          <ApprovalRequestModal
            form={this.form}
            order={CRUD.data}
            open={isApprovalRequestModalOpen}
            onConfirm={() => {
              this.setState({
                readOnly: true,
                readOnlyCoupon: true,
              })
              this.gotoOrders()
            }}
            onClose={() => {
              this.setFieldValidations(true)
              this.toggleApprovalRequestModal()
            }}
            formAction="quotation"
            handleCancel={this.handleCancel}
          />
        )}
      </React.Fragment>
    )
  }
}

export default inject(
  'orderRepository',
  'orderItemRepository',
  'commonRepository',
  'customerRepository',
  'customerAddressRepository',
  'customerContactRepository',
  'customerGroupRepository',
  'customerGroupDetailsRepository',
  'storeRepository',
  'shippingRateRepository',
  'sanctionCustomerRepository',
  'bankAccountRepository'
)(observer(OrderForm))
